/**
 * This class defines four methods for apps to request assets and themes from the Player.
 */

import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * Assets API is a part of the Player SDK used for fetching assets and theme.
 * Use global `enplug.assets` object to access these methods.
 *
 * ```typescript title="Example usage of the Assets API"
 * const asset = await enplug.assets.getNext();
 * ```
 *
 * Useful commands:
 * - {@link getNext|`enplug.assets.getNext()`} - fetch the value of the asset that is to be displayed
 * - {@link getTheme|`enplug.assets.getTheme()`} - get the theme
 */
export default class Assets {
  /** @internal */
  constructor(private bridge: Bridge) {}

  /** @internal */
  getList(): Promise<any[]> {
    return this.bridge.send(Service.Assets, Action.GetList);
  }

  /**
   * Fetches the asset that is to be displayed by the app.
   *
   * When it is time to present certain asset of your app, the Player will instantiate a new iframe
   * with a new instance of your app. Then, the app can fetch the [value](/docs/sdk-dashboard/interfaces/Asset#value)
   * of the asset, so it has access to all the settings and customizations made on the Dashboard part of the app.
   *
   * ```typescript title="Fetching the asset's value"
   * const assetValue = await enplug.assets.getNext();
   * ```
   *
   * @param T - Model of asset's value
   *
   * @returns Returns a Promise that resolves to the single asset value.
   * This is what was saved as 'asset.Value' on the Dashboard part of the app.
   */
  getNext<T>(): Promise<T> {
    return this.bridge.send(Service.Assets, Action.GetNext);
  }

  getNextForPlayList<T>(): Promise<T> {
    return this.bridge.send(Service.Assets, Action.GetNextForPlayList);
  }

  /** @internal */
  getAsset(): Promise<any> {
    return this.bridge.send(Service.Assets, Action.GetAsset);
  }

  getTheme<T>(): Promise<T> {
    return this.bridge.send(Service.Assets, Action.GetTheme);
  }
}
